
/**
 *
 */
class Page {
	constructor(options) {
		this.page_id = options.page_id || Grape.utils.generate_random_string(10);
		this._class = options._class || options.page_class;
		this.template = options.template || null;
		this.template_filename = options.template_filename || null;

		this.instances = [];
	}

	/**
	 * Gets a template based on the value of window.currentLanguageCode
	 */
	get_template() {
		if (!this.template_filename || !window.translated_pages || !window.currentLanguageCode)
			return this.template;

		if (
			window.translated_pages[this.template_filename] &&
			window.translated_pages[this.template_filename][window.currentLanguageCode]
		)
			return window.translated_pages[this.template_filename][window.currentLanguageCode];
		else
			return this.template;
	}

	/**
	 * Loads page into element
	 */
	load(element, bindings, params, options) {
		const self = this;
		return new Promise(function (resolve, reject) {
			let p = self.create_instance(element, bindings, params, options);
			p.then(function (instance) {
				let ud_promise = null;

				if (instance.updateData instanceof Function)
				{
					try
					{
						let p = instance.updateData.call(instance, bindings, params, options);
						if (p && p instanceof Promise)
							ud_promise = p;
					} catch (e)
					{
						console.error('Error while executing updateData function for page:');
						console.error(e);
					}
				}

				if (!ud_promise)
					ud_promise = new Promise(function (resolve, reject) { resolve(bindings); });
				ud_promise.then(function () {
					resolve(instance);
				}).catch(function (err) {
					reject(err);
				});
			}).catch(function (err) {
				console.error(err);
				reject(err);
			});
		});
	}

	/**
	 * Removes instance from element
	 */
	teardown(instance, element) {
		const self = this;
		return new Promise(function (resolve, reject) {
			// window.Grape.clean_shortcuts(page_obj); TODO

			let teardown_p = null;
			// Call custom teardown function belonging to that Page Object
			if (typeof instance.teardown === 'function')
				teardown_p = instance.teardown.call(instance);
			element.innerHTML = '';
			if (!teardown_p || !teardown_p.then)
				teardown_p = Promise.resolve();
			teardown_p.then(function () {
				ko.cleanNode(element);

				if (self.instances.indexOf(instance) >= 0)
					self.instances.splice(self.instances.indexOf(instance), 1);
				resolve();
			});
		});
	}

	/**
	 * Creates a new page instance, loads template into element and call init()
	 * @param {DOMElement} element -
	 * @param {Object} bindings -
	 * @return {Promise}
	 */
	create_instance(element, bindings, params, options) {
		const self = this;
		return new Promise(function (resolve, reject) {
			element.innerHTML = self.get_template();

			let instance = new self._class(bindings, params, options, element, self);
			instance.element = element;
			self.instances.push(instance);

			// Knockout
			if (instance.viewModel)
			{
				console.debug('Applying knockout bindings');
				try
				{
					ko.cleanNode(element);
				} catch (e)
				{
					console.error('Unable to clean node', element);
				}

				try
				{
					ko.applyBindings(instance.viewModel, element);
				} catch (e)
				{
					console.error('Error while applying bindings to page vm=', instance.viewModel, 'target=', element, 'message=', e.message);
					console.error(e);
				}
			}

			let init_promise = null;

			if (instance.init)
			{
				try
				{
					let p = instance.init.call(instance);
					if (p && p instanceof Promise)
						init_promise = p;
				} catch (e)
				{
					console.error('Error while executing init function for page:');
					console.error(e);
				}
			}

			if (!init_promise)
				init_promise = new Promise(function (resolve, reject) { resolve(); });

			init_promise.then(function () {
				resolve(instance);
			}).catch(function (err) {
				reject(err);
			});
		});
	}

	get_instance_by_element(element) {
		return this.instances.find(function (a) { return a.element == element; });
	}
}

export default Page;
